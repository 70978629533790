import React from 'react'

const HSHeader = () => {
  return (
    <div>
      Hs Header
    </div>
  )
}

export default HSHeader
